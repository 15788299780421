import { extendTheme } from "@chakra-ui/react";
import "@fontsource/lexend";
import "@fontsource/lexend/700.css";
import "@fontsource/mulish";
import "@fontsource/outfit";

const theme = {
  colors: {
    pink: "#FFF5EE",
    dkBlue: "#26275E",
    dkGrey: "#3b3b3b",
    btBlue: "rgba(2, 99, 224, 0.89)",
  },
  fonts: {
    heading: "Lexend, sans-serif",
    body: "Mulish, sans-serif",
    number: "Outfit, sans-serif",
  },
};

export default extendTheme(theme);
